import request from '@/utils/request'

// 查询用户参与活动列表
export function listActivity(query) {
  return request({
    url: '/ctivity/activity/list',
    method: 'get',
    params: query
  })
}

// 查询用户参与活动详细
export function getActivity(id) {
  return request({
    url: '/ctivity/activity/' + id,
    method: 'get'
  })
}

// 新增用户参与活动
export function addActivity(data) {
  return request({
    url: '/ctivity/activity',
    method: 'post',
    data: data
  })
}

// 修改用户参与活动
export function updateActivity(data) {
  return request({
    url: '/ctivity/activity',
    method: 'put',
    data: data
  })
}

// 删除用户参与活动
export function delActivity(id) {
  return request({
    url: '/ctivity/activity/' + id,
    method: 'delete'
  })
}
