<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!--      <a-form-model-item label="主键" prop="id" v-if="formType === 1">
              <a-input v-model="form.id" placeholder="请输入主键" />
            </a-form-model-item>
            <a-form-model-item label="创建人" prop="createBy" >
              <a-input v-model="form.createBy" placeholder="请输入创建人" />
            </a-form-model-item>
            <a-form-model-item label="修改人" prop="updateBy" >
              <a-input v-model="form.updateBy" placeholder="请输入修改人" />
            </a-form-model-item>
            <a-form-model-item label="报名时间" prop="applyTime" >
              <a-input v-model="form.applyTime" placeholder="请输入报名时间" />
            </a-form-model-item>
            <a-form-model-item label="活动时间，10已经报名，待开始；20，活动中，30完成，40未完成" prop="activityStatus" >
            </a-form-model-item>
            <a-form-model-item label="活动id。activity_activity.id" prop="activityId" >
              <a-input v-model="form.activityId" placeholder="请输入活动id。activity_activity.id" />
            </a-form-model-item>
            <a-form-model-item label="完成数量" prop="completeNum" >
              <a-input v-model="form.completeNum" placeholder="请输入完成数量" />
            </a-form-model-item>
            <a-form-model-item label="申请邮寄时间" prop="applyPostTime" >
              <a-date-picker style="width: 100%" v-model="form.applyPostTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
            </a-form-model-item>-->
      <!--      <a-form-model-item label="用户id user_user.id" prop="userId" >
              <a-input v-model="form.userId" placeholder="请输入用户id user_user.id" />
            </a-form-model-item>-->
      <!-- 关联套餐奖励 -->
      <a-form-model-item label="关联套餐奖励" prop="rebindIds">
        <api-select v-model="form.rebindIds" placeholder="请选择套餐奖励" api="/activity/activity/reward/select-list" multi
                    :searchKeys="['name']">
          <template v-slot="{ option }">
            <span>{{ option.name }}</span>
          </template>
        </api-select>
      </a-form-model-item>
      <!-- 关联周边奖励 -->
      <a-form-model-item label="关联周边奖励" prop="sibindIds">
        <api-select v-model="form.sibindIds" placeholder="请选择题材" api="/activity/activity/side/select-list" multi
                    :searchKeys="['name']">
          <template v-slot="{ option }">
            <span>{{ option.name }}</span>
          </template>
        </api-select>
      </a-form-model-item>
      <a-form-model-item label="是否邮寄" prop="isPost">
        <a-switch v-model="form.isPost" />{{ form.isPost ? '是' : '否' }}
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getActivity, addActivity, updateActivity} from '@/api/ctivity/activity'
import ApiSelect from '@/views/config/badge/modules/ApiSelect.vue';
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from "vuex";

export default {
  name: 'CreateForm',
  props: {},
  components: {
    ApiSelect, CustomDictTag
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createBy: null,

        updateBy: null,

        createTime: null,

        updateTime: null,

        applyTime: null,

        activityStatus: '0',

        activityId: null,

        completeNum: null,

        applyPostTime: null,
        rebindIds: [],
        sibindIds: [],
        userId: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          {required: true, message: '用户id user_user.id不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,

        createBy: null,

        updateBy: null,

        createTime: null,

        updateTime: null,

        applyTime: null,

        activityStatus: '0',

        activityId: null,

        completeNum: null,

        applyPostTime: null,
        rebindIds: [],
        sibindIds: [],
        userId: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getActivity(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateActivity(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addActivity(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
