<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户名称" prop="nickName">
                <a-input v-model="queryParam.nickName" placeholder="请输入用户名称" allow-clear/>
              </a-form-item>
            </a-col>
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="报名时间" prop="applyTime">-->
            <!--                <a-input v-model="queryParam.applyTime" placeholder="请输入报名时间" allow-clear/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="活动名称" prop="activityName">
                <a-input v-model="queryParam.activityName" placeholder="请输入活动名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="活动状态" prop="activityStatus">
                <a-select placeholder="请选择活动状态" style="width: 100%" v-model="queryParam.activityStatus" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.ActivityUserStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">

              <!--              <a-col :md="8" :sm="24">
                              <a-form-item label="创建人" prop="createBy">
                                <a-input v-model="queryParam.createBy" placeholder="请输入创建人" allow-clear/>
                              </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24">
                              <a-form-item label="修改人" prop="updateBy">
                                <a-input v-model="queryParam.updateBy" placeholder="请输入修改人" allow-clear/>
                              </a-form-item>
                            </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['ctivity:activity:add']">
                  <a-icon type="plus" />新增
                </a-button>
                <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['ctivity:activity:edit']">
                  <a-icon type="edit" />修改
                </a-button>
                <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['ctivity:activity:remove']">
                  <a-icon type="delete" />删除
                </a-button>-->
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['ctivity:activity:export']">
                  <a-icon type="download" />导出
                </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="applyPostTime" slot-scope="text, record">
          {{ parseTime(record.applyPostTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['ctivity:activity:edit']"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['ctivity:activity:edit']">
            <a-icon type="edit"/>查看详情
          </a>
          <!--          <a-divider type="vertical" v-hasPermi="['ctivity:activity:remove']" />
                    <a @click="handleDelete(record)" v-hasPermi="['ctivity:activity:remove']">
                      <a-icon type="delete" />删除
                    </a>-->
        </span>
        <span slot="activityStatus" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.ActivityUserStatusEnum" :value="record.activityStatus"/>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {listActivity, delActivity} from '@/api/ctivity/activity'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'Activity',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        createBy: null,
        updateBy: null,
        applyTime: null,
        activityStatus: null,
        activityId: null,
        completeNum: null,
        applyPostTime: null,
        userId: null,
        nickName: null,
        activityName: null,
        receiverAddress: null,
        receiverProvinceName: null,
        receiverCityName: null,
        receiverDistrictName: null,
        receiverMobile: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '活动名称',
          dataIndex: 'activityName',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '创建人',
          dataIndex: 'createBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '修改人',
          dataIndex: 'updateBy',
          ellipsis: true,
          align: 'center'
        },*/
        /* {
           title: '创建时间',
           dataIndex: 'createTime',
           scopedSlots: { customRender: 'createTime' },
           ellipsis: true,
           align: 'center'
         },*/
        /*{
          title: '报名时间',
          dataIndex: 'applyTime',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '活动状态',
          dataIndex: 'activityStatus',
          ellipsis: true,
          scopedSlots: {customRender: 'activityStatus'},
          align: 'center'
        },
        {
          title: '收货人省份',
          dataIndex: 'receiverProvinceName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收货人所在城市',
          dataIndex: 'receiverCityName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收货人所在街道',
          dataIndex: 'receiverDistrictName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收货人的手机号码',
          dataIndex: 'receiverMobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '详细地址',
          dataIndex: 'receiverAddress',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '完成数量',
        //   dataIndex: 'completeNum',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '申请邮寄时间',
        //   dataIndex: 'applyPostTime',
        //   scopedSlots: { customRender: 'applyPostTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    /** 查询用户参与活动列表 */
    getList() {
      this.loading = true
      listActivity(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        createBy: undefined,
        updateBy: undefined,
        applyTime: undefined,
        activityStatus: undefined,
        activityId: undefined,
        completeNum: undefined,
        applyPostTime: undefined,
        userId: undefined,
        nickname: undefined,
        activityName: undefined,
        receiverAddress: undefined,
        receiverProvinceName: undefined,
        receiverCityName: undefined,
        receiverDistrictName: undefined,
        receiverMobile: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delActivity(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('ctivity/activity/export', {
            ...that.queryParam
          }, `activity_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
